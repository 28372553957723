.answerIcon {

    width: 128px;
    height: auto;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;

    cursor: pointer;
}
